<template>
  <div class="bars w-100" v-bind:class="active">
    <span id="overlay" v-on:click="closeNav"></span>

    <!-- Sidebar start -->

    <div class="custom-sidebar closenav" id="Sidenav-bar" v-if="showNavbars">
      <div class="logo">
        <!-- <router-link :to="{ name: 'user-dashboard' }"> -->

        <img src="@/assets/images/Datagma-logo.svg" alt="" />

        <!-- </router-link> -->

        <span class="close-nav mobile-screen"> </span>
      </div>

      <ul>
        <!-- <li
    
        
    
                  v-if="getLocalStorage()"
    
        
    
                  v-bind:class="[
    
        
    
                    this.$route && this.$route.name === 'get-started' ? 'active' : '',
    
        
    
                  ]"
    
        
    
                >
    
        
    
                  <router-link :to="{ name: 'get-started' }">
    
        
    
                    <span class="nav-icon">
    
        
    
                      <img
    
        
    
                        class="d-block"
    
        
    
                        width="24"
    
        
    
                        src="@/assets/images/icons/get-started.svg"
    
        
    
                      /> </span
    
        
    
                    >Get Started</router-link
    
        
    
                  >
    
        
    
                </li> -->

        <li v-b-toggle.collapse-search="false" v-bind:class="[
    this.$route.name === 'easy-search' ||
      this.$route.name === 'file-upload' ||
      this.$route.name === 'advance-phone-search' ||
      this.$route.name === 'my-files'
      ? 'active'
      : ' collapsed',
  ]">
          <span class="nav-icon">
            <img class="d-block" width="24" src="@/assets/images/icons/get-started.svg" /> </span>Search
        </li>

        <b-collapse visible id="collapse-search" accordion="my-accordion" v-bind:class="[
    this.$route.name === 'easy-search' ||
      this.$route.name === 'file-upload' ||
      this.$route.name === 'advance-phone-search' ||
      this.$route.name === 'my-files'
      ? 'show'
      : '',
  ]">
          <ul style="background-color: #eaedf0">
            <li v-bind:class="[
    this.$route && this.$route.name === 'easy-search'
      ? 'active'
      : '',
  ]">
              <router-link :to="{ name: 'easy-search' }">
                <span class="nav-icon">
                  <img class="d-block" width="24" src="@/assets/images/icons/get-started.svg" /> </span>Easy
                Search</router-link>
            </li>

            <li v-bind:class="[
    this.$route && this.$route.name === 'search-demo'
      ? 'active'
      : '',
  ]">
              <router-link :to="{ name: 'advance-phone-search' }">
                <span class="nav-icon">
                  <img class="d-block" width="24" src="@/assets/images/icons/get-started.svg" /> </span>Advanced Phone
                Search</router-link>
            </li>

            <li v-bind:class="[
    this.$route && this.$route.name === 'file-upload'
      ? 'active'
      : '',
  ]">
              <router-link :to="{ name: 'file-upload' }">
                <span class="nav-icon">
                  <img class="d-block" width="24" src="@/assets/images/icons/get-started.svg" /> </span>File
                Upload</router-link>
            </li>

            <li v-bind:class="[
    this.$route && this.$route.name === 'my-files' ? 'active' : '',
  ]">
              <router-link :to="{ name: 'my-files' }">
                <span class="nav-icon">
                  <img class="d-block" width="24" src="@/assets/images/icons/my-files.svg" /></span>My
                Files</router-link>
            </li>
          </ul>
        </b-collapse>

        <li v-bind:class="[
    this.$route && this.$route.name === 'contacts' ? 'active' : '',
  ]">
          <router-link :to="{ name: 'contacts' }">
            <span class="nav-icon">
              <img class="d-block" width="24" src="@/assets/images/icons/get-started.svg" /> </span>Contacts
            History</router-link>
        </li>

        <!-- <li
          v-bind:class="[
            this.$route && this.$route.name === 'enrich-contact'
              ? 'active'
              : '',
          ]"
        >
          <router-link :to="{ name: 'enrich-contact' }">
            <span class="nav-icon">
              <img
                class="d-block"
                width="24"
                src="@/assets/images/icons/hubspot-1.svg"
              /> </span
            >Hubspot Enrichment</router-link
          >
        </li> -->

        <li>
          <a :href="chromeExtentionLink" target="_blank">
            <span class="nav-icon">
              <img class="d-block" width="24" src="@/assets/images/icons/google-chrome.svg" /> </span>Extension
          </a>
        </li>

        <li v-b-toggle.collapse-3="false" v-bind:class="[
    this.$route.name == 'enrich-contact' ? 'active' : ' collapsed',
  ]">
          <router-link :to="{ name: 'userIntegration' }">
            <span class="nav-icon">
              <img class="d-block" width="24"
                src="@/assets/images/icons/integrations.svg" /></span>Integration</router-link>
        </li>

        <b-collapse id="collapse-3" accordion="my-accordion"
          v-bind:class="[this.$route.name === 'enrich-contact' ? 'show' : '']">
          <ul style="background-color: #eaedf0">
            <li v-bind:class="[
    this.$route && this.$route.name == 'enrich-contact'
      ? 'active'
      : '',
  ]">
              <router-link :to="{ name: 'enrich-contact' }">
                <span class="nav-icon">
                  <img class="d-block" width="24" src="@/assets/images/icons/hubspot-1.svg" /> </span>HubSpot
                Enrichment</router-link>
            </li>
          </ul>
        </b-collapse>
        <li v-b-toggle.collapse-2="false" v-bind:class="[
    this.$route.name === 'userApi' ||
      this.$route.name === 'userDemo' ||
      this.$route.name === 'userJobChangeDetection'
      ? 'active'
      : ' collapsed',
  ]">
          <span class="nav-icon">
            <img class="d-block" width="24" src="@/assets/images/icons/apikey.svg" /> </span>API
        </li>

        <b-collapse id="collapse-2" accordion="my-accordion" v-bind:class="[
    this.$route.name === 'userApi' ||
      this.$route.name === 'userDemo' ||
      this.$route.name === 'userJobChangeDetection'
      ? 'show'
      : '',
  ]">
          <ul style="background-color: #eaedf0">
            <li v-bind:class="[
    this.$route && this.$route.name === 'userDemo' ? 'active' : '',
  ]">
              <router-link :to="{ name: 'userDemo' }">
                <span class="nav-icon">
                  <img class="d-block" width="24" src="@/assets/images/icons/my-files.svg" /> </span>Enrich
                Demo</router-link>
            </li>

            <li v-bind:class="[
    this.$route && this.$route.name == 'userJobChangeDetection'
      ? 'active'
      : '',
  ]">
              <router-link :to="{ name: 'userJobChangeDetection' }">
                <span class="nav-icon">
                  <img class="d-block" width="24" src="@/assets/images/icons/my-files.svg" /> </span>Job Change
                demo</router-link>
            </li>

            <li v-bind:class="[
    this.$route && this.$route.name == 'userApi' ? 'active' : '',
  ]">
              <router-link :to="{ name: 'userApi' }">
                <span class="nav-icon">
                  <img class="d-block" width="24" src="@/assets/images/icons/apikey.svg" /></span>
                Key & Doc</router-link>
            </li>
            <li>
              <a :href="ChangelogLink" target="_blank">
                <span class="nav-icon">
                  <img class="d-block" width="24" src="@/assets/images/icons/megaphone-svgrepo-com.svg" />
                </span>Changelog
              </a>
            </li>
          </ul>
        </b-collapse>

        <!-- <li
    
        
    
                  v-bind:class="[
    
        
    
                    this.$route && this.$route.name == 'fundraising' ? 'active' : '',
    
        
    
                  ]"
    
        
    
                >
    
        
    
                  <router-link :to="{ name: 'fundraising' }">
    
        
    
                    <span class="nav-icon">
    
        
    
                      <img
    
        
    
                        class="d-block"
    
        
    
                        width="24"
    
        
    
                        src="@/assets/images/icons/get-started.svg"
    
        
    
                      />
    
        
    
                    </span>
    
        
    
                    Fundraising
    
        
    
                  </router-link>
    
        
    
                </li> -->

        <!-- <li
    
        
    
                  v-bind:class="[
    
        
    
                    this.$route && this.$route.name == 'saastr' ? 'active' : '',
    
        
    
                  ]"
    
        
    
                >
    
        
    
                  <router-link :to="{ name: 'saastr' }">
    
        
    
                    <span class="nav-icon">
    
        
    
                      <img
    
        
    
                        class="d-block"
    
        
    
                        width="24"
    
        
    
                        src="@/assets/images/icons/get-started.svg"
    
        
    
                      />
    
        
    
                    </span>
    
        
    
                    SAASTR Europa
    
        
    
                  </router-link>
    
        
    
                </li> -->

        <li>
          <a :href="PlaybookLink" target="_blank">
            <span class="nav-icon">
              <img class="d-block" width="24" src="@/assets/images/icons/google-chrome.svg" /> </span>Playbooks
          </a>
        </li>

        <li v-bind:class="[
    this.$route && this.$route.name == 'new-pricing'
      ? 'active orange-pricing-menu'
      : 'orange-pricing-menu',
  ]">
          <router-link :to="{ name: 'new-pricing' }">
            <span class="nav-icon">
              <img class="d-block" width="24" src="@/assets/images/icons/get-started.svg" />
            </span>
            Upgrade
          </router-link>
        </li>

        <li v-b-toggle.collapse-5="false" v-bind:class="[
    this.$route.name === 'refferalLink' ||
      this.$route.name === 'change-password'
      ? 'active'
      : ' collapsed',
  ]">
          <span class="nav-icon">
            <img class="imges" height="24px" src="@/assets/images/network.png" alt="logo" /> </span>My Account
        </li>

        <b-collapse id="collapse-5" accordion="my-accordion" v-bind:class="[
    this.$route.name === 'refferalLink' ||
      this.$route.name === 'change-password'
      ? 'show'
      : '',
  ]">
          <ul style="background-color: #eaedf0">
            <li class="last-child user-detail">
              <div class="image-box">
                <div class="image-box-content">
                  <span class="font-lg-16">{{ userEmail }}</span>
                </div>
              </div>
            </li>

            <!-- <li class="last-child user-detail">
              <div class="image-box">
                <div id="basic" class="image-box-content"></div>
                <router-link :to="{ name: 'userStats' }">Stats</router-link>
              </div>
            </li> -->

            <li class="last-child user-detail">
              <div class="image-box">
                <div id="basic" class="image-box-content"></div>

                <!-- <a href="javascript:void(0)" data-cb-type="portal">
    
        
    
                          Manage Billing
    
        
    
                        </a> -->

                <router-link :to="{ name: 'manageBilling' }">Manage Billing</router-link>
              </div>
            </li>

            <li v-bind:class="[
    this.$route && this.$route.name == 'refferalLink'
      ? 'active'
      : '',
  ]">
              <router-link :to="{ name: 'refferalLink' }">
                <!-- <span class="nav-icon">
    
        
    
                          <img
    
        
    
                            class="imges"
    
        
    
                            height="24px"
    
        
    
                            src="@/assets/images/network.png"
    
        
    
                            alt="logo"
    
        
    
                          />
    
        
    
                        </span> -->

                Earn Free Credits
              </router-link>
            </li>

            <li class="nav-item">
              <router-link :to="{ name: 'change-password' }">
                Change Password
              </router-link>
            </li>
            <li class="nav-item">
              <router-link :to="{ name: 'whatsapp-settings' }">
                Whatsapp Settings
              </router-link>
            </li>

            <li class="nav-item">
              <a href="javascript:void(0)" @click="logout"> Logout</a>
            </li>
          </ul>
        </b-collapse>
      </ul>

      <p class="user-credit-box" style="display: flex; justify-content: space-between; align-items: center;">
        <span><span>{{ userCredit }}</span>Enrichment credits</span>
      </p>

      <p class="user-credit-box">
        <span>{{ phoneCredit }}</span> Phone number available

        <span class="pricing-tooltip custom-tooltip ">
          <img src="../../../../assets/images/icons/tooltip.svg" alt=""
            v-b-tooltip.bottom.hover.v-primary="'How do credits work ?'" variant="primary" @click="phoneCreditsFun()" />
        </span>
      </p>
      <!-- Update Credits UI -->
      <p class="user-credit-box refreshtoken-menu"
        style="background-color: transparent; color: #000; display: flex; justify-content: space-between; align-items: center;padding: 0;">
        <span style="font-weight: 400;">Credits last updated :
          <span>{{ getFormattedDate(creditLastUpdated) }}</span></span>
        <span @click="updateCredits(areCreditsBeingUpdated)" class="loader-rotate"
          v-bind:class="areCreditsBeingUpdated ? 'activerotate' : ''"><img
            src="../../../../assets/images/refresh-icon.svg" alt="" /></span>
      </p>
    </div>

    <!-- sidebar End -->

    <!-- Top start -->

    <div class="top-bar" id="top-bar" v-show="isTopBarVisible">
      <div class="content">
        <div class="title">
          <span class="menu-btn" v-on:click="openNav">&#9776;</span>
        </div>

        <div class="action-bar">
          <ul>
            <li class="nav-item">
              <button v-on:click="upgardeNavigate" class="secondary-btn-outline">
                Upgrade
              </button>
            </li>

            <li class="action-bar">
              <button class="secondary-btn-outine">
                ChangeLog
              </button>

              <div class="headway-badge changelog-badge" id="headway-badge"></div>
            </li>

            <!-- <li>
              <a>
                <img
                  src="@/assets/images/icons/notification-bell.svg"
                  alt="Datagma"
                />

                <div class="headway-badge" id="headway-badge"></div>
              </a>
            </li> -->
            <!-- <li class="nav-item">
    
        
    
                      <button v-on:click="changePassword" class="secondary-btn-outline">
    
        
    
                        Change Password
    
        
    
                      </button>
    
        
    
                    </li>
    
        
    
        
    
        
    
                    <li class="nav-item">
    
        
    
                      <button v-on:click="logout" class="secondary-btn-outline">
    
        
    
                        Logout
    
        
    
                      </button>
    
        
    
                    </li> -->
          </ul>
        </div>
      </div>
    </div>

    <!-- Top start -->
  </div>
</template>
<script>
import {
  LOG_OUT,
  USER_EMAIL,
  // USER_CREDIT,
  ID_TOKEN_KEY,
  GET_USER_CREDIT,
} from "../../../store/constant/actions.type";
// import $ from "jquery";
import { creditService } from "../../common/services/getUserCredit.service";
import { analyticsService } from "../../common/services/analytics.service";
import moment from "moment";
// import io from "socket.io-client";
export default {
  name: "leftTopbar",
  data: function () {
    return {
      active: "active",
      user_detail: {},
      // collapse1: "",
      // collapse2: "",
      userEmail:
        localStorage.getItem(USER_EMAIL) &&
          localStorage.getItem(USER_EMAIL) != undefined &&
          localStorage.getItem(USER_EMAIL) !== null
          ? localStorage.getItem(USER_EMAIL)
          : "",
      userCredit: 0,
      phoneCredit: "",
      showNavbars: true,
      isTopBarVisible: true,
      firstTime: false,
      creditLastUpdated: new Date(),
      areCreditsBeingUpdated: false
    };
  },

  created() {
    this.subscription = creditService.getCredit().subscribe((Credit) => {
      if (Credit) {
        // $(".userCredit").text(Credit);
      } else {
        // $(".userCredit").text("*");
      }
    });
    let externalScript = document.createElement("script");
    externalScript.onload = () => {
      window.Chargebee.init({});
      window.Chargebee.registerAgain();
    };
    externalScript.setAttribute(
      "src",
      "https://js.chargebee.com/v2/chargebee.js"
    );
    // externalScript.setAttribute("data-cb-site", "datagma");
    document.head.appendChild(externalScript);
  },
  beforeMount() {
    this.user_detail = this.$store.getters.getstoreUser;
    const currentRoute = this.$route.path;
    this.firstTime = localStorage.getItem("firstTime");
    this.firstTime = true;
    if (currentRoute.includes("get-started") && this.firstTime) {
      this.showNavbars = false;
      this.isTopBarVisible = false;
    } else if (currentRoute.includes("easy-search") && !this.firstTime) {
      this.showNavbars = true;
      this.isTopBarVisible = true;
    }
    this.$eventBus.$on("callCreditMethod", () => {
      this.getUserCardit();
    });
    this.userCredit = 0;
    this.phoneCredit = 0;
    if (
      localStorage.getItem(USER_EMAIL) &&
      this.user_detail?.currentCredit &&
      localStorage.getItem(USER_EMAIL) !== undefined
    ) {
      this.userEmail = localStorage.getItem(USER_EMAIL);
      this.userCredit = parseInt(this.user_detail?.currentCredit);
      this.phoneCredit = (parseInt(this.userCredit) / 75)
        .toString()
        .split(".")[0];
    }
    let createHeadWayScript = document.createElement("script");
    createHeadWayScript.async = true;
    createHeadWayScript.src = "https://cdn.headwayapp.co/widget.js";
    document.head.appendChild(createHeadWayScript);
    var config = {
      selector: ".headway-badge",
      account: "JAKWqx",
    };
    createHeadWayScript.onload = function () {
      window.Headway.init(config);
    };
  },
  // mounted() {
  //   this.handleRouteAndStorage();
  //   this.$eventBus.$on("easySearchMounted", () => {
  //     this.handleRouteAndStorage();
  //   });
  // },
  mounted() {
    this.$eventBus.$on("easySearchMounted", () => {
      this.handleRouteAndStorage();
    });

    // socket.on("wh_data_log", (wh_data_log) => {
    //   this.userCredit = wh_data_log.userCredit
    // });

    // // Get the current route path
    // const currentRoute = this.$route.path;
    // console.log("in mount");
    // this.firstTime = localStorage.getItem("firstTime");
    // this.firstTime = true;
    // console.log("current route and firstTime", this.firstTime, currentRoute);
    // if (currentRoute.includes("get-started") && this.firstTime) {
    //   this.showNavbars = false;
    //   this.isTopBarVisible = false;
    // } else if (currentRoute.includes("easy-search") && !this.firstTime) {
    //   console.log("in mount 861");
    //   this.showNavbars = true;
    //   this.isTopBarVisible = true;
    // }
  },

  methods: {
    handleRouteAndStorage() {
      const currentRoute = this.$route.path;
      this.firstTime = localStorage.getItem("firstTime");
      if (currentRoute.includes("get-started") && this.firstTime) {
        this.showNavbars = false;
        this.isTopBarVisible = false;
      } else if (currentRoute.includes("easy-search") || !this.firstTime) {
        this.showNavbars = true;
        this.isTopBarVisible = true;
      } else if (!currentRoute.includes("get-started")) {
        this.showNavbars = true;
        this.isTopBarVisible = true;
      }
    },

    getLocalStorage() {
      let a = localStorage.getItem("firstTime");
      if (a) {
        return true;
      }
    },
    phoneCreditsFun() {
      analyticsService.userPhoneNumberAvailable(this.user_detail);
      window.open(
        "http://help.datagma.com/en/articles/6665031-how-credits-works",
        "_blank"
      );
    },
    getFormattedDate(dateString) {
      return moment(dateString).format('ddd DD MMMM YYYY hh:mm A');
    },
    updateCredits(isUpdating) {
      if (isUpdating) {
        return
      }
      this.areCreditsBeingUpdated = true;
      this.getUserCardit()
    },
    getUserCardit() {
      let apiId = localStorage.getItem("$s");
      if (
        localStorage.getItem(USER_EMAIL) &&
        localStorage.getItem(USER_EMAIL) !== undefined &&
        localStorage.getItem(ID_TOKEN_KEY) &&
        localStorage.getItem(ID_TOKEN_KEY) !== undefined &&
        apiId &&
        apiId !== undefined
      ) {
        let email = localStorage.getItem(USER_EMAIL);
        let payload = {
          apiId: apiId,
          email: email,
        };
        this.$store
          .dispatch(GET_USER_CREDIT, payload)
          .then((data) => {
            if (data && data.currentCredit) {
              //---Updating the credit updation time
              this.creditLastUpdated = new Date()
              this.userCredit = data.currentCredit;
              this.phoneCredit = (parseInt(this.userCredit) / 75)
                .toString()
                .split(".")[0];
            }
            //---Here we are updating the current credit of the user
            const alteredPayload = { ...this.user_detail, currentCredit: data.currentCredit }

            this.$store.dispatch(
              "setStoreUserData",
              alteredPayload
            );
          })
          .catch((err) => {
            this.flashMessage.error({
              title: "Error Message",
              message: err.message,
            });
          }).finally(() => {
            this.areCreditsBeingUpdated = false
          });
      }
    },
    // showData(callFrom) {
    //   //this is use to handle active class and collapse
    //   if (callFrom == "collapse1") {
    //     localStorage.removeItem("collapse2");
    //     localStorage.setItem(callFrom, true);
    //     this.collapse1 = true;
    //     this.collapse2 = false;
    //   } else if (callFrom == "collapse2") {
    //     localStorage.removeItem("collapse1");
    //     localStorage.setItem(callFrom, true);
    //     this.collapse2 = true;
    //     this.collapse1 = false;
    //   } else {
    //     localStorage.removeItem("collapse1");
    //     localStorage.removeItem("collapse2");
    //   }
    // },
    user() {
      this.$router.push({ name: "user" });
    },
    logout() {
      const resetdata = {
        firstName: "",
        lastName: "",
        email: "",
        currentCredit: "",
        phone: "",
        companyName: "",
        phoneCountryCode: "",
        countryCode: "",
        countryName: "",
        job: "",
        ip: "",
        signupCode: "",
        planId: "",
      };
      this.$store.dispatch("setStoreUserData", resetdata);
      this.$store.dispatch("changeWhatsAppPro", null);
      this.$store.dispatch(LOG_OUT).then(() => {
        this.$router.push({ name: "signin" }).catch((error) => {
          console.info("logout error", error.message);
        });
      });
    },
    changePassword() {
      this.$router.push({ name: "change-password" });
    },
    upgardeNavigate() {
      this.$router.push({ name: "new-pricing" });
    },
    // openLinkInUrl(url) {
    //   window.open(url, "_blank");
    // },
    openNav() {
      var a = document.getElementById("Sidenav-bar");
      var f = document.getElementById("maincontentapi");
      var g = document.getElementById("top-bar");
      a.classList.toggle("closenav");
      a.classList.toggle("mainopen");
      f.classList.toggle("main-open");
      g.classList.toggle("main-open");
      document.getElementById("overlay").style.display = "block";
    },
    closeNav() {
      var b = document.getElementById("Sidenav-bar");
      var m = document.getElementById("Sidenav-bar");
      var n = document.getElementById("maincontentapi");
      var p = document.getElementById("top-bar");
      m.classList.remove("mainopen");
      n.classList.remove("main-open");
      p.classList.remove("main-open");
      b.classList.add("closenav");
      document.getElementById("overlay").style.display = "none";
    },
  },

  computed: {
    chromeExtentionLink: function () {
      return "https://datagma.com/chrome-extension/";
    },
    PlaybookLink: function () {
      return "https://datagma.com/playbook/";
    },
    ChangelogLink: function () {
      return "https://headwayapp.co/datagma-changelog";
    },
    // isTopBarVisible() {
    //   // Check if the current route is not "get-started"
    //   return this.$route.name !== 'get-started';
    // },
  },

  beforeDestroy() {
    // unsubscribe to ensure no memory leaks
    this.subscription.unsubscribe();
  },
};
</script>
<style scoped>
/* sidebar style */

.custom-sidebar {
  position: fixed;
  left: 0;
  height: 100%;
  background: #ffffff;
  transition: 0.3s;
  overflow-y: auto;
  overflow-x: hidden;
  width: 300px;
  z-index: 111;
  padding: 0 12px 0 12px;
  box-shadow: 4px 2px 4px rgb(0 0 0 / 5%);
}

.logo img {
  width: 120px;
  /* max-width: 116px; */
  /* margin-right: 10px; */
}

.custom-sidebar::-webkit-scrollbar {
  width: 0px;
}

.logo {
  padding: 24px 152px 24px 16px;
}

.custom-sidebar ul {
  padding: 0;
  margin: 0;
}

.custom-sidebar ul li {
  list-style: none;
  margin: 10px 0;
}

.custom-sidebar ul li a {
  color: #a5adba;
  /* max-width: 208px; */
  padding: 0px 0px;
  display: block;
  font-weight: 400;
  font-family: "Montserrat", sans-serif;
}

.custom-sidebar ul li a:hover {
  background: #2589ff;
  border-radius: 4px;
  color: #fff;
}

.custom-sidebar ul li a:hover svg path,
.custom-sidebar ul li.active svg path {
  fill: #fff;
}

.custom-sidebar ul li.active a {
  color: #fff;
}

.custom-sidebar ul li a span {
  position: relative;
  padding-right: 12px;
  display: inline-block;
}

.custom-sidebar ul li {
  color: #a5adba;
  /* max-width: 208px; */
  padding: 7px 10px;
  display: block;
  font-size: 1rem;
}

.custom-sidebar ul li:hover {
  background: #2589ff;
  border-radius: 4px;
  color: #fff;
}

/* .custom-sidebar ul :hover {
  background: #2589ff;
  border-radius: 4px;
  color: #fff;
} */

.custom-sidebar ul li.active img,
.custom-sidebar ul li:hover img {
  filter: brightness(0) invert(1);
}

.custom-sidebar ul li :hover svg path,
.custom-sidebar ul li.active svg path {
  fill: #fff;
}

.custom-sidebar ul li.active {
  color: #fff;
}

.custom-sidebar ul li span {
  position: relative;
  padding-right: 12px;
  display: inline-block;
  font-size: 1rem;
}

.action-bar {
  position: relative;
}

.custom-sidebar ul li.active {
  background: #2589ff;
  border-radius: 4px;
}

.headway-badge {
  position: absolute;
  top: 5px;
}

.changelog-badge ::v-deep .HW_badge_cont {
  width: 100%;
  height: 40px;
}

.changelog-badge ::v-deep #HW_badge {
  top: -10px;
  left: 110px;
}

.changelog-badge {
  position: absolute;
  top: 0px;
  left: 20px;
  right: 0;
}

/* .custom-sidebar ul li a span img {
  width: 26px;
  height: auto;
} */

.custom-sidebar ul li:hover .image-box-content strong {
  color: #fff;
}

@media only screen and (max-width: 769px) {
  .custom-sidebar {
    width: 250px;
  }
}

/* topbar style */

.top-bar {
  position: relative;
  z-index: 2;
  float: right;
  width: calc(100% - 300px);
  min-height: 100%;
  overflow: auto;
  padding: 8px 30px;
  background-color: #ffff;
  /* box-shadow: 0 2px 5px 0 rgb(0 0 0 / 16%), 0 2px 10px 0 rgb(0 0 0 / 12%); */
  z-index: 4;
}

.menu-btn {
  font-size: 24px;
  color: #2589ff;
  cursor: pointer;
  line-height: 0;
}

.navbar-brand {
  font-weight: 600;
  font-size: 16px;
  color: #ffffff;
  text-transform: uppercase;
}

.content {
  display: flex;
  justify-content: space-between;
  /* justify-content: center; */
  align-items: center;
  width: 96%;
}

.action-bar ul li {
  float: left;
  list-style: none;
  height: 40px;
  display: grid;
  place-content: center;
  padding-left: 20px;
}

.action-bar ul li a {
  color: #ffff;
  display: inline;
  padding-right: 20px;
}

.action-bar ul li a span {
  position: relative;
  padding-right: 4px;
  top: 6px;
}

.user-detail {
  /* position: absolute; */
  bottom: 0;
}

#overlay {
  position: fixed;
  display: none;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 99;
  cursor: pointer;
}

.mainopen.custom-sidebar ul li a,
.mainopen.custom-sidebar ul li {
  font-size: 0px;
}

.mainopen.custom-sidebar ul li {
  padding-bottom: 12px;
}

.mainopen.custom-sidebar:hover ul li {
  padding-bottom: 10px;
}

.custom-sidebar.mainopen {
  width: 72px;
}

.custom-sidebar.mainopen:hover {
  width: 300px;
}

.custom-sidebar.mainopen .image-box-content span {
  font-size: 0px;
}

.custom-sidebar.mainopen:hover .image-box-content span,
.custom-sidebar .image-box-content span {
  font-size: 16px;
}

.mainopen.custom-sidebar:hover ul li a,
.mainopen.custom-sidebar:hover ul li,
.closenav.custom-sidebar ul li a,
.closenav.custom-sidebar ul li,
.custom-sidebar.mainopen:hover .image-box-content span {
  font-size: 16px;
  transition-delay: 0.3s;
  transition-duration: 0.1s;
  transition-property: font-size;
}

.custom-sidebar.mainopen .logo {
  opacity: 0;
}

.custom-sidebar:hover .logo {
  opacity: 1;
}

.top-bar {
  transition: all 0.3s;
}

.top-bar.main-open {
  width: calc(100% - 72px);
}

.custom-sidebar.mainopen ul li span {
  padding-right: 0;
}

.custom-sidebar:hover ul li span {
  padding-right: 12px;
}

@media only screen and (min-width: 567px) {
  #overlay {
    display: none !important;
  }
}

@media only screen and (max-width: 769px) {
  .top-bar {
    width: calc(100% - 250px);
  }

  .secondary-btn-outline {
    font-size: 13px;
    line-height: 22px;
    padding: 3px 9px;
  }

  .action-bar ul li {
    padding-left: 6px;
  }

  .action-bar ul {
    margin: 0;
    padding: 0;
  }
}

@media only screen and (max-width: 576px) {
  .custom-sidebar.mainopen .logo {
    opacity: 1;
  }

  .top-bar {
    width: 100%;
  }

  .custom-sidebar {
    box-shadow: 0 2px 5px 0 rgb(0 0 0 / 16%), 0 2px 10px 0 rgb(0 0 0 / 12%);
  }

  .custom-sidebar.closenav {
    width: 0px;
    padding: 0;
  }

  .close-nav {
    position: absolute;
    top: 20px;
    right: 10px;
  }

  .custom-sidebar.mainopen {
    width: auto;
  }

  .custom-sidebar.mainopen .image-box-content span {
    font-size: 12px;
  }

  .mainopen.custom-sidebar ul li a,
  .mainopen.custom-sidebar ul li {
    font-size: 16px;
  }

  .custom-sidebar.mainopen ul li span {
    padding-right: 10px;
  }
}

@media only screen and (max-width: 320px) {
  .action-bar ul li a {
    padding-right: 10px;
  }

  .btn-outline-danger {
    padding: 0.275rem 0.45rem;
    font-size: 0.8rem;
  }
}

.imges {
  filter: opacity(0.3);
}

.custom-sidebar ul li img:not(.imges) {
  position: relative;
  top: 5px;
}

.custom-sidebar ul li:hover .image-box-content strong {
  color: #fff;
}

.user-credit-box {
  background: #2589ff;
  border-radius: 4px;
  padding: 8px 8px;
  font-size: 14px;
  color: #fff;
}

.user-credit-box span {
  min-width: 34px;
  display: inline-block;
  font-weight: bold;
}

.custom-sidebar ul li.orange-pricing-menu {
  background: #ff9000;
  border-radius: 4px;
}

.custom-sidebar ul li.orange-pricing-menu:hover a {
  background: #ff9000;
  border-radius: 4px;
}

.custom-sidebar ul li.orange-pricing-menu a {
  color: #fff;
  position: relative;
  top: -2px;
}

.custom-sidebar ul li.orange-pricing-menu a img {
  filter: brightness(0) invert(1);
}

span.pricing-tooltip.custom-tooltip {
  text-align: end;
}

.user-credit-box {
  background: #2589ff;
  border-radius: 4px;
  padding: 8px 8px;
  font-size: 14px;
  color: #fff;
}

.user-credit-box span {
  min-width: 34px;
  display: inline-block;
  font-weight: bold;
  margin-right: 6px;
}

.secondary-btn-outine {
  background: none;
  color: #003172;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  padding: 7px 18px;
  border: 1px solid #003172;
  border-radius: 4px;
}

button.secondary-btn-outine:hover {
  background: #2589ff;
  color: #fff;
  border: 1px solid #2589ff;
  transition: all 0.5s;
}

.loader-rotate img {
  width: 20px;
  height: 20px;

}
.loader-rotate{
  cursor: pointer;
}
.loader-rotate.activerotate img {
  animation: 1s rotate linear infinite;

}
.refreshtoken-menu span {
    color: #a5adba;
}
@keyframes rotate {
  100% {
    transform: rotate(360deg)
  }
}
</style>
